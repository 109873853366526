import { z } from 'zod';

/**
 * Defines an interface that is able to send and receive AI chat messages.
 */
export interface AIChatInterface {
    /**
     * Sends a chat request to the AI API.
     * @param request The request to send.
     */
    chat(request: AIChatInterfaceRequest): Promise<AIChatInterfaceResponse>;
}

/**
 * Defines an interface that represents an AI chat request.
 */
export interface AIChatInterfaceRequest {
    /**
     * The model that should be used.
     */
    model: string;

    /**
     * The messages that should be sent to the AI.
     */
    messages: AIChatMessage[];

    /**
     * The temperature of the request.
     */
    temperature: number;

    /**
     * The nucleus sampling probability.
     */
    topP?: number;

    /**
     * The presence penalty.
     *
     * Positive values penalize new tokens based on whether they appear in the text so far, increasing the model's likelihood to talk about new topics.
     */
    presencePenalty?: number;

    /**
     * The frequency penalty.
     *
     * Positive values penalize new tokens based on their existing frequency in the text so far, decreasing the model's likelihood to repeat the same line verbatim.
     */
    frequencyPenalty?: number;

    /**
     * The list of stop words that should be used.
     *
     * If the AI generates a sequence of tokens that match one of the given words, then it will stop generating tokens.
     */
    stopWords?: string[];

    /**
     * The maximum number of tokens that should be generated by the model.
     */
    maxTokens?: number;

    /**
     * The ID of the user that is making the request.
     */
    userId: string;
}

/**
 * Defines an interface that represents an AI chat response.
 */
export interface AIChatInterfaceResponse {
    /**
     * The messages that the AI responded with.
     */
    choices: AIChatMessage[];

    /**
     * The total number of tokens that were used.
     */
    totalTokens: number;
}

/**
 * Defines an interface that represents a single chat message in a conversation with an AI.
 *
 * @dochash types/ai
 * @docname AIChatMessage
 */
export interface AIChatMessage {
    /**
     * The role of the message.
     *
     * - `system` means that the message was generated by the system. Useful for telling the AI how to behave while.
     * - `user` means that the message was generated by the user.
     * - `assistant` means that the message was generated by the AI assistant.
     * - `function` means that the message contains the results of a function call.
     */
    role: 'system' | 'user' | 'assistant' | 'function';

    /**
     * The contents of the message.
     */
    content: string;

    /**
     * The name of the author of the message.
     *
     * This is required if the role is `function`.
     */
    author?: string;

    /**
     * The reason why the message was finished.
     */
    finishReason?: string;

    /**
     * @hidden
     */
    functionCall?: AIFunctionCall;
}

/**
 * Defines a schema that represents an AI chat message.
 */
export const AI_CHAT_MESSAGE_SCHEMA = z.object({
    role: z.union([
        z.literal('system'),
        z.literal('user'),
        z.literal('assistant'),
        z.literal('function'),
    ]),
    content: z.string().nonempty(),
    author: z.string().nonempty().optional(),
});
type ZodAIChatMessage = z.infer<typeof AI_CHAT_MESSAGE_SCHEMA>;
type ZodAIChatMessageAssertion = HasType<ZodAIChatMessage, AIChatMessage>;

/**
 * Defines an interface that represents an AI function call.
 */
export interface AIFunctionCall {
    name: string;

    /**
     * The arguments that should be passed to the function.
     */
    arguments: {
        [key: string]: any;
    };
}

type HasType<T, Q extends T> = Q;
